<template>
  <v-dialog v-model="dialog" width="400px">
    <template v-slot:activator="{ on, attrs }">
      <div v-if="client.description">
        <span v-bind="attrs" v-on="on" @click="descDialog = true" class="black--text caption font-italic text-center row no-gutters justify-center">
          {{ client.description }}
        </span>
      </div>
      <div v-else>
        <v-chip v-bind="attrs" v-on="on" small color="primary" outlined @click="dialog = true">
          <span class="text-body-2">Заметка +</span>
        </v-chip>
      </div>
    </template>
    <v-card shaped class="pa-3">
      <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
        <v-toolbar-title>Заметка</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-1">
        <v-col cols="12">
          <v-textarea dense
                      hide-details
                      label="Введите текст"
                      outlined
                      rounded
                      v-model="item.description" />
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-space-around">
        <v-btn rounded outlined elevation="3" color="error" @click="dialog = false">Отменить</v-btn>
        <v-btn v-if="item.description !== client.description"
               color="primary" elevation="3" rounded outlined @click="save">
          Сохранить
        </v-btn>
        <v-btn v-else disabled elevation="3" color="grey darken-3" rounded outlined>Сохранить</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

import {uuid} from "uuidv4";

export default {
  props: {
    client: Object
  },
  data() {
    return {
      item: {
        id: uuid.Nil,
        description: "",
      },
      dialog: false
    }
  },

  mounted() {
    this.item.id = this.client.id;
    if(this.client.description) {
      this.item.description = this.client.description;
    } else {
      return ""
    }
    this.item.blocked = this.client.blocked;
  },

  methods: {
    save() {
      this.$store.dispatch("clients/save", Object.assign({}, this.item));
      this.dialog = false;
    },
  }
}
</script>
