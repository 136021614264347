<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row no-gutters">
        <v-col class="row no-gutters justify-center d-flex align-center" cols="11">
          <span>Пользователей : {{ count }}</span>
        </v-col>
        <v-col class="row no-gutters justify-lg-center justify-md-center justify-sm-center justify-end d-flex align-center" cols="1">
          <v-tooltip left>
            <template v-slot:activator="{ attrs, on }">
              <v-icon @click="download" v-bind="attrs" v-on="on" large>mdi-microsoft-excel</v-icon>
            </template>
            <span>Выгрузить в Excel</span>
          </v-tooltip>
        </v-col>
      </v-toolbar-title>
    </v-toolbar>
    <v-row class="mx-5 mb-2 mt-1 justify-space-around">
      <v-col cols="12" class="col col-lg-4 col-md-4 col-sm-4">
        <VueCtkDateTimePicker
            id="date"
            v-model="date"
            :range="temp"
            button-color="#3FC44F"
            class="mt-2"
            color="#3FC44F"
            format="YYYY-MM-DDTHH:mm:ssZ"
            label="Выберите промежуток времени"
            locale="ru"
            @input="filter"/>
      </v-col>
      <v-col cols="12" :class="$vuetify.breakpoint.xs ? 'pt-0' : ''" class="col col-lg-4 col-md-4 col-sm-4">
        <v-text-field v-model="search"
                      prepend-inner-icon="mdi-magnify"
                      class="mt-2"
                      clearable
                      dense
                      hide-details
                      label="Поиск по номеру тел."
                      outlined
                      single-line
                      @change="filter"/>
      </v-col>
      <v-col cols="12" :class="$vuetify.breakpoint.xs ? 'pt-0' : ''" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-center d-flex align-center">
        <v-radio-group v-model="blacklist" row class="py-0 ma-0 pt-2" dense hide-details @change="filter">
          <v-radio id="selected" value="true" label="В черном списке"></v-radio>
          <v-radio id="unselected" value="false" label="Не в черном списке"></v-radio>
          <v-radio id="all" value="all" label="Все"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense class="px-2 hidden-xs-only">
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Изображение</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">ФИО</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Телефон</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Заметки</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Ч.С.</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-space-around">
        <span class="text-caption">Дата рег.</span>
        <span class="text-caption">Дата акт.</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Инфо</span>
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.xs ? 'no-gutters pa-2' : ''" v-for="client in list" :key="client.id" class="grey lighten-4 mx-1 my-1">
      <v-col class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Фото:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
        <v-tooltip right>
          <template v-slot:activator="{ attrs, on }">
            <v-avatar v-if="client.image" v-bind="attrs" v-on="on" size="25">
              <img :src="client.image" alt="Client image">
            </v-avatar>
            <v-avatar v-else v-bind="attrs" v-on="on" size="25">
              <img src="/user_avatar.png" alt="Client avatar">
            </v-avatar>
          </template>
          <v-avatar v-if="client.image" size="96">
            <img alt="" :src="client.image">
          </v-avatar>
          <v-avatar v-else size="96">
            <img alt="" src="/user_avatar.png">
          </v-avatar>
        </v-tooltip>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Имя:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
        <div v-if="client.name" class="text-body-2 font-weight-bold">{{ client.name }}</div>
        <div v-else class="caption font-italic">Без имени</div>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Тел.:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
        <div v-if="client.phone.length < 18" class="text-body-2">{{ client.phone | normalizePhone }}</div>
        <div v-else class="caption font-italic">Нет регистрации</div>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Заметка:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
        <edit :client="client"/>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Черный список:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
        <v-checkbox v-model="client.blocked"
                    hide-details
                    class="ma-0 pa-0"
                    @change="toggle(client)"/>
      </v-col>
      <v-col cols="12" class="row no-gutters col col-lg-3 col-md-3 col-sm-3 d-flex justify-space-around align-center">
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Дата рег.:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-6 col-md-6 col-sm-6 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center caption text-no-wrap">
          {{ client.created_at | timeFormat }}
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Дата акт.:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-6 col-md-6 col-sm-6 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center caption text-no-wrap">
          {{ client.updated_at | timeFormat }}
        </v-col>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Инфо:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
        <info :client="client"/>
      </v-col>
    </v-row>
    <v-pagination
        v-model="currentPage"
        :length="paginationCount"
        total-visible="9"
        @input="fetchPage">
    </v-pagination>
  </v-card>
</template>

<script>
import format from 'date-fns/format';
import axios from "@/services/axios";
import info from "./details.vue";
import edit from "./edit.vue";

export default {
  components: {info, edit},
  data() {
    return {
      limit: 10,
      search: "",
      temp: true,
      date: {},
      currentPage: 1,
      blacklist: "all"
    }
  },

  created() {
    this.fetch();
  },

  computed: {
    list() {
      return this.$store.getters["clients/list"]
    },
    count() {
      return this.$store.getters["clients/count"]
    },
    paginationCount() {
      if (!this.list || !this.count) {
        return 0
      }
      return Math.ceil(this.count / this.limit)
    }
  },
  methods: {
    toggle(client) {
      if (client.blocked) {
        this.$store.dispatch("clients/add", client.id);
      } else {
        this.$store.dispatch("clients/remove", client.id);
      }
    },
    fetchPage() {
      this.fetch();
    },
    filter() {
      this.currentPage = 1;
      this.fetch();
    },
    fetch() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit, blacklist: this.blacklist}
      if (this.search && this.search.length > 0) {
        payload.phone = this.search
      }
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      this.$store.dispatch("clients/fetch", queryString)

    },
    download() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit, blacklist: this.blacklist}
      if (this.search && this.search.length > 0) {
        payload.phone = this.search
      }
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      axios({
        url: "/clients/export?" + queryString,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clients.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
    dateFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd")
    },
    timeFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd HH:mm")
    }
  }
}
</script>
