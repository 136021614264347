<template>
  <v-dialog v-model="dialog" width="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
    </template>
    <v-card class="pt-2">
      <v-img class="mx-2" height="130px" src="/user_background.jpeg">
        <v-row no-gutters class="justify-center">
          <v-avatar class="mt-3" size="48px">
            <v-img v-if="item.image" :src="item.image" />
            <v-img v-else src="/user_avatar.png" />
          </v-avatar>
        </v-row>
        <v-row no-gutters class="justify-center mt-2">
          <span class="white--text" v-if="item.name">{{ item.name }}</span>
          <span class="white--text" v-else>Без имени</span>
        </v-row>
        <v-row no-gutters class="justify-center mt-2">
          <span v-if="item.phone" class="text-body-2 white--text">{{ item.phone | normalizePhone }}</span>
          <span v-else class="text-body-2 white--text">Нет регистрации</span>
        </v-row>
      </v-img>
      <v-card-text class="pb-2 px-2">
        <v-row no-gutters v-if="item.email" class="mt-1 py-3 grey lighten-4 justify-center">
          <v-icon class="mr-3">mdi-email</v-icon>
          <span class="font-weight-bold">{{ item.email }}</span>
        </v-row>
        <v-row no-gutters v-else class="mt-1 py-3 grey lighten-4 justify-center">
          <span class="font-italic text-body-2">Email не указан</span>
        </v-row>
        <v-row no-gutters v-if="item.gender === 'FEMALE'" class="mt-1 py-3 grey lighten-4 justify-center">
          <v-icon class="mr-3">mdi-gender-female</v-icon>
          <span class="font-weight-bold">Женский</span>
        </v-row>
        <v-row no-gutters v-else-if="item.gender === 'MALE'" class="mt-1 py-3 grey lighten-4 justify-center">
          <v-icon class="mr-3">mdi-gender-male</v-icon>
          <span class="font-weight-bold">Мужской</span>
        </v-row>
        <v-row no-gutters v-else class="mt-1 py-3 grey lighten-4 justify-center">
          <span class="font-italic text-body-2">Пол не указан</span>
        </v-row>
        <v-row no-gutters v-if="item.birthday" class="mt-1 py-3 grey lighten-4 justify-center">
          <v-icon class="mr-3">mdi-cake-variant</v-icon>
          <span class="font-weight-bold">{{ item.birthday | dateFormat }}</span>
        </v-row>
        <v-row no-gutters v-else class="mt-1 py-3 grey lighten-4 justify-center">
          <span class="font-italic text-body-2">ДР не указан</span>
        </v-row>
        <v-row no-gutters class="mt-1 py-3 grey lighten-4 justify-center">
          <v-icon class="mr-3">mdi-clock-start</v-icon>
          <span class="font-weight-bold">{{ item.created_at | timeFormat }}</span>
        </v-row>
        <v-row no-gutters class="mt-1 py-3 grey lighten-4 justify-center">
          <v-icon class="mr-3">mdi-update</v-icon>
          <span class="font-weight-bold">{{ item.updated_at | timeFormat }}</span>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import format from "date-fns/format";
import {uuid} from "uuidv4";

export default {
  props: {
    client: Object
  },
  data() {
    return {
      item: {
        id: uuid.Nil,
        gender: "",
        email: "",
        birthday: ""
      },
      dialog: false
    };
  },

  mounted() {
    this.item.id = this.client.id;
    this.item.name = this.client.name;
    this.item.phone = this.client.phone;
    this.item.image = this.client.image;
    this.item.gender = this.client.gender;
    this.item.email = this.client.email;
    this.item.birthday = this.client.birthday;
    this.item.created_at = this.client.created_at;
    this.item.updated_at = this.client.updated_at;
  },

  filters: {
    dateFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd")
    },
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
    timeFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd HH:mm")
    },
  },
};
</script>
